import {
  getSurveyDetailApi,
  getSurveyLocationFilterApi,
  getSurveyDetailFiltersApi,
  getSurveyVisualizationsApi,
  getSurveyQuestionsCategoriesApi,
  getSurveySectionDetailsApi,
  getSurveyJobApi,
  postSurveyVisualizationsApi,
  getSurveyShareALinkApi,
  getShareALinkByShareTokenApi,
} from '../api/surveys';
import MliVisualizationDetails from '../helpers/analytics/models/mliVisualizationDetails';
import navigationTracker from '../helpers/analytics/navigationTracker';
import types from '../types';

export const getSurveyLocationFilter = (id, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_LOCATION_FILTER_PENDING });
    const data = await getSurveyLocationFilterApi(id, jwt);
    dispatch({ type: types.reducerTypes.SURVEY_LOCATION_FILTER_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.SURVEY_LOCATION_FILTER_ERROR, payload: e });
  }
};

export const getSurveyDetail = (id, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_DETAILS_PENDING });
    const data = await getSurveyDetailApi(id, jwt);
    dispatch({ type: types.reducerTypes.SURVEY_DETAILS_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.SURVEY_DETAILS_ERROR, payload: e });
  }
};

export const getSurveyDetailFilters = (id, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_DETAILS_FILTERS_PENDING });
    const data = await getSurveyDetailFiltersApi(id, jwt);
    dispatch({ type: types.reducerTypes.SURVEY_DETAILS_FILTERS_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SURVEY_DETAILS_FILTERS_ERROR,
      payload: e,
    });
  }
};

export const getSurveyVisualizations = (id, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_DETAILS_VISUALIZATIONS_PENDING });
    const data = await getSurveyVisualizationsApi(id, jwt);
    dispatch({ type: types.reducerTypes.SURVEY_DETAILS_VISUALIZATIONS_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SURVEY_DETAILS_VISUALIZATIONS_ERROR,
      payload: e,
    });
  }
};

export const postSurveyVisualizations = (
  id,
  visualization,
  jwt,
  updateVisualization = true
) => async (dispatch) => {
  try {
    const data = await postSurveyVisualizationsApi(id, visualization, jwt);
    if (updateVisualization) {
      dispatch({ type: types.reducerTypes.SURVEY_DETAILS_VISUALIZATIONS_SUCCESS, payload: data });
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SURVEY_DETAILS_VISUALIZATIONS_ERROR,
      payload: e,
    });
  }
};

export const getSurveyQuestionsCategories = (id, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_DETAILS_QUESTIONS_CATEGORIES_PENDING });
    const data = await getSurveyQuestionsCategoriesApi(id, jwt);
    dispatch({
      type: types.reducerTypes.SURVEY_DETAILS_QUESTIONS_CATEGORIES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SURVEY_DETAILS_QUESTIONS_CATEGORIES_ERROR,
      payload: e,
    });
  }
};

export const getSurveySectionDetails = (id, visualization, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_DETAILS_SECTION_PENDING });
    const data = await getSurveySectionDetailsApi(id, visualization, jwt);
    const location = data?.headers?.location;
    dispatch({
      type: types.reducerTypes.SURVEY_DETAILS_SECTION_SUCCESS,
      payload: location?.substring(location?.lastIndexOf('/') + 1),
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SURVEY_DETAILS_SECTION_ERROR,
      payload: e,
    });
  }
};

export const calculateMoe = (id, visualization, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_DETAILS_CALCULATE_MOE_PENDING });
    const data = await getSurveySectionDetailsApi(id, visualization, jwt, true);
    navigationTracker.onCalculateSectionMarginOfError(
      { id, name: 'SectionName' },
      new MliVisualizationDetails(visualization.details)
    );
    const location = data?.headers?.location;
    dispatch({
      type: types.reducerTypes.SURVEY_DETAILS_CALCULATE_MOE_SUCCESS,
      payload: location?.substring(location?.lastIndexOf('/') + 1),
    });
  } catch (e) {
    console.error(e);
    dispatch({
      type: types.reducerTypes.SURVEY_DETAILS_CALCULATE_MOE_ERROR,
      payload: e.message,
    });
  }
};

export const getSurveyJob = (id, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_DETAILS_JOB_PENDING });
    const data = await getSurveyJobApi(id, jwt);
    dispatch({
      type: types.reducerTypes.SURVEY_DETAILS_JOB_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SURVEY_DETAILS_JOB_ERROR,
      payload: e,
    });
  }
};

export const getSurveyShareALink = (idVisualization, idSurvey, idActiveElem, jwt) => async (
  dispatch
) => {
  try {
    dispatch({ type: types.reducerTypes.SHARE_A_LINK_PENDING });
    const data = await getSurveyShareALinkApi(idVisualization, idSurvey, idActiveElem, jwt);
    dispatch({ type: types.reducerTypes.SHARE_A_LINK_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SHARE_A_LINK_ERROR,
      payload: e,
    });
  }
};

export const getSurveyShareALinkByShareToken = (sharedToken, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_DETAILS_VISUALIZATIONS_PENDING });
    const data = await getShareALinkByShareTokenApi(sharedToken, jwt);
    dispatch({
      type: types.reducerTypes.SURVEY_DETAILS_VISUALIZATIONS_SUCCESS,
      payload: data.visualization,
    });
    dispatch({
      type: types.reducerTypes.SHARE_A_LINK_BY_TOKEN_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SURVEY_DETAILS_VISUALIZATIONS_ERROR,
      payload: e,
    });
  }
};
